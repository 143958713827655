/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';
import { motion } from 'framer-motion';

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="2"
    stroke="currentColor"
    strokeLinecap="round"
    {...props}
  />
);

export const IconAddRemove = (props) => (
  <motion.svg viewBox="0 0 32 32" initial="add" animate={props.selected ? 'remove' : 'add'}>
    <Path
      variants={{
        add: { d: 'M 16 5 L 16 27' },
        remove: { d: 'M 24 8 L 8 24' },
      }}
    />

    <Path
      variants={{
        add: { d: 'M 5 16 L 27 16' },
        remove: { d: 'M 8 8 L 24 24' },
      }}
    />
  </motion.svg>
);
