/** @jsx jsx */
import { jsx, Flex, Text, Container, Button, AspectRatio } from 'theme-ui';
import React, { useMemo } from 'react';
import { Link, graphql } from 'gatsby';

import { useFontLoader } from '../woff-provider/use-font-loader';
import { BlockContent } from '../components/block-content';
import SEO from '../components/seo';
import GraphQLErrorList from '../components/graphql-error-list';
import { Stack, Row, Grid, Col } from '../components/grid';
import { ProductFree } from '../components/product-free';
import { PromoBanner } from '../components/promo-banner';

const guidSuffix = process.env.GATSBY_ENVIRONMENT === 'live' ? '_live' : '_test';

const TrialFonts = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    );
  }

  return (
    <>
      <SEO {...data.page.seo} />
      {data.page.promoBanner && <PromoBanner {...data.page.promoBanner} />}
      <Container
        sx={{
          borderBottom: '1px solid',
          borderColor: 'line',
        }}
      >
        <Row>
          <Stack variant="0">
            <Stack variant="1" as="header">
              <Text as="h1" variant="section_header" sx={{ opacity: 0.5 }}>
                {data.page.banner_subtitle}
              </Text>
              <Text
                as="p"
                className="type"
                variant="f5_typeface_tagline"
                sx={{ fontWeight: 'bold' }}
              >
                {data.page.banner_title}
              </Text>
            </Stack>
            <BlockContent blocks={data.page.content} />

            <Stack gap={[9, 10, 10]}>
              <ProductFree
                id="f5-trial-fonts"
                title={data.page.product_title}
                subtitle={data.page.product_subtitle}
                guid={data.page[`guid${guidSuffix}`]}
              />

              <div
                sx={{
                  borderTop: 'line',
                  borderColor: 'line',
                  paddingTop: [9, 10, 10],
                }}
              >
                <Stack gap={[9, 10, 10]}>
                  <Flex
                    sx={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Stack sx={{ maxWidth: ['15rem'], textAlign: 'right' }} gap={7}>
                      <Link to="/cart" sx={{ variant: 'buttons.primary' }}>
                        Checkout
                      </Link>

                      <Text variant="small" sx={{ color: 'txt.3', maxWidth: '35ch' }}>
                        {data.page.footnote}
                      </Text>
                    </Stack>
                  </Flex>
                </Stack>
              </div>
            </Stack>
          </Stack>
        </Row>
      </Container>
    </>
  );
};

export const query = graphql`
  query TrialsPageQuery {
    page: sanityPageTrials {
      id
      seo {
        ...SanityPageSeoFragment
      }
      promoBanner {
        ...SanityPromoBannerFragment
      }
      banner_title
      banner_subtitle
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      footnote
      product_title
      product_subtitle
      guid_test
      guid_live
      seo {
        ...SanityPageSeoFragment
      }
    }
  }
`;

export default TrialFonts;
