/** @jsx jsx */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Text, Flex, jsx } from 'theme-ui';

import { TextF5 } from './text-f5';
import { useCart } from '../cart-provider';

import { Stack } from './grid';
import { IconAddRemove } from './icon-add-remove';

const ProductButton = ({ selected, ...props }) => (
  <button
    {...props}
    sx={{
      textAlign: 'left',
      position: 'relative',
      bg: selected ? 'accent' : 'bg.1',
      color: selected ? 'txt.4' : 'text',
      transition: 'background-color 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',
      pt: [6, 7, 8],
      pb: [6, 7, 8],
      px: [6, 7, 8],
      display: 'flex',
      flexDirection: ['column', 'row'],
      justifyContent: ['flex-start'],
      alignItems: [null, 'center'],
      '& .vat, & .subtitle': {
        opacity: selected ? 1 : 0.6,
      },
      '&:hover': {
        '.vat, .subtitle': {
          opacity: 1,
        },
      },
    }}
  />
);

export const ProductFree = ({ id, guid, title, subtitle, description }) => {
  const [selected, setSelected] = useState(false);

  const { cart, ready } = useCart();

  const isInCart = cart[id];

  useEffect(() => {
    setSelected(isInCart);
  }, [isInCart]);

  const removeFromCart = useCallback(async () => {
    try {
      const state = await window.Snipcart.store.getState();
      const item = state.cart.items.items.find((i) => i.id === id);
      if (!item) return;

      await window.Snipcart.api.cart.items.remove(item.uniqueId);
    } catch (error) {}
  }, [id]);

  const addToCart = useCallback(async () => {
    try {
      const data = {
        id: id,
        url: '/products',
        basePrice: 0,
        description: description,
        name: title,
        quantity: 1,
        maxQuantity: 1,
        taxable: true,
        shippable: false,
        stackable: false,
        fileGuid: guid.trim(),
      };
      await window.Snipcart.api.cart.items.add(data);
    } catch (error) {
      console.log(error);
    }
  }, [id, ready]);

  const buttonProps = !ready
    ? {
        onClick: (e) => {
          e.preventDefault();
          return false;
        },
      }
    : selected
    ? {
        onClick: () => {
          if (isInCart) {
            removeFromCart();
            setSelected(false);
          }
        },
      }
    : {
        onClick: (e) => {
          addToCart();
          setSelected(true);
        },
      };

  return (
    <>
      <Stack gap={4}>
        <ProductButton selected={selected} {...buttonProps}>
          <Stack gap={4} sx={{ flex: 1 }}>
            <Text variant="f5_typeface_product" sx={{ flex: 1, fontWeight: 'bold' }}>
              {title}
            </Text>
            {subtitle && (
              <Text variant="small" className="subtitle">
                {subtitle}
              </Text>
            )}
          </Stack>
          <Flex sx={{ flex: '0 auto', alignItems: 'center' }}>
            <Flex
              sx={{
                flex: 1,
                alignItems: 'center',
                mt: [8, 0],
                width: '100%',
                position: 'relative',
              }}
            >
              <Text as="span" className="price" variant="h3" sx={{ mr: [6, 8], flex: '0 auto' }}>
                Free
              </Text>
            </Flex>
            <div
              sx={{
                width: [9, 10],
                ml: [0, 8, 12],
                position: ['absolute', 'relative'],
                right: [6, 'auto'],
                top: [8, 'auto'],
              }}
            >
              <IconAddRemove selected={selected} />
            </div>
          </Flex>
        </ProductButton>
      </Stack>
    </>
  );
};
